var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap"},[(_vm.loading)?_c('Spin',{attrs:{"fix":""}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.viewVisible),expression:"!viewVisible"}]},[_c('CtmsSearchBox',{on:{"onSearch":_vm.searchHandle,"onReset":_vm.reset}},[_c('CtmsSearchBoxItem',{attrs:{"span":1,"label":"姓名"}},[_c('Input',{model:{value:(_vm.searchParams.name),callback:function ($$v) {_vm.$set(_vm.searchParams, "name", $$v)},expression:"searchParams.name"}})],1),_c('CtmsSearchBoxItem',{attrs:{"span":1,"label":"证件号码"}},[_c('Input',{model:{value:(_vm.searchParams.idCardNo),callback:function ($$v) {_vm.$set(_vm.searchParams, "idCardNo", $$v)},expression:"searchParams.idCardNo"}})],1),_c('CtmsSearchBoxItem',{attrs:{"span":1,"label":"黑名单"}},[_c('Select',{attrs:{"clearable":"","transfer":true,"placeholder":"请选择"},model:{value:(_vm.searchParams.isBlack),callback:function ($$v) {_vm.$set(_vm.searchParams, "isBlack", $$v)},expression:"searchParams.isBlack"}},[_c('Option',{attrs:{"value":1}},[_vm._v("是")]),_c('Option',{attrs:{"value":0}},[_vm._v("否")])],1)],1)],1),_c('CtmsAction',[(
					!(
						_vm.systemActionPermissions.indexOf('btn_subject_patients_add') === -1
					)
				)?_c('Button',{on:{"click":_vm.addHandle}},[_vm._v("\n\t\t\t\t添加受试者\n\t\t\t")]):_vm._e(),(
					!(
						_vm.systemActionPermissions.indexOf('btn_subject_patients_add') === -1
					)
				)?_c('Button',{attrs:{"loading":_vm.exportLoading},on:{"click":_vm.exportHandle}},[_vm._v("\n\t\t\t\t导出\n\t\t\t")]):_vm._e()],1),_c('CtmsDataGrid',{attrs:{"height":_vm.dataGridHeight,"columns":_vm.columns,"data":_vm.listData,"page":_vm.page}})],1),_c('Add',{attrs:{"visible":_vm.addVisible,"sexList":_vm.sexList,"id":_vm.updateId},on:{"onCancel":function($event){_vm.addVisible = false},"onOk":_vm.handleAddOk}}),_c('ViewData',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewVisible),expression:"viewVisible"}],attrs:{"visible":_vm.viewVisible,"viewId":_vm.viewId},on:{"onCancel":function($event){_vm.viewVisible = false},"onOk":_vm.handleViewOk}})],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }