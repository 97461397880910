import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/patient-local/patient"

export default {
	...commonApi(moduleName),
	getSubjectList: data =>
		request("/patient-local/patients", {
			params: data
		})
}
