<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>

		<div v-show="!viewVisible">
			<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
				<CtmsSearchBoxItem :span="1" label="姓名">
					<Input v-model="searchParams.name"></Input>
				</CtmsSearchBoxItem>
				<CtmsSearchBoxItem :span="1" label="证件号码">
					<Input v-model="searchParams.idCardNo"></Input>
				</CtmsSearchBoxItem>
				<CtmsSearchBoxItem :span="1" label="黑名单">
					<Select
						v-model="searchParams.isBlack"
						clearable
						:transfer="true"
						placeholder="请选择"
					>
						<Option :value="1">是</Option>
						<Option :value="0">否</Option>
					</Select>
				</CtmsSearchBoxItem>
			</CtmsSearchBox>
			<CtmsAction>
				<Button
					@click="addHandle"
					v-if="
						!(
							systemActionPermissions.indexOf('btn_subject_patients_add') === -1
						)
					"
				>
					添加受试者
				</Button>
				<Button
					@click="exportHandle"
					:loading="exportLoading"
					v-if="
						!(
							systemActionPermissions.indexOf('btn_subject_patients_add') === -1
						)
					"
				>
					导出
				</Button>
			</CtmsAction>
			<CtmsDataGrid
				:height="dataGridHeight"
				:columns="columns"
				:data="listData"
				:page="page"
			>
			</CtmsDataGrid>
		</div>
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:sexList="sexList"
			:id="updateId"
		></Add>
		<ViewData
			v-show="viewVisible"
			:visible="viewVisible"
			@onCancel="viewVisible = false"
			@onOk="handleViewOk"
			:viewId="viewId"
		></ViewData>
	</div>
</template>

<script>
import { mapState } from "vuex"
import api from "@/api/subject/subjectlist"
import pubApi from "@/api/public"
import ViewData from "@/views/Subject/viewData.vue"
import { paramsStringify } from "@/utils/util"
import Add from "./Add.vue"

const { publicGetDictionaryList } = pubApi

const { getSubjectList, apiDelete } = api

export default {
	data() {
		return {
			loading: false,
			columns: [
				{
					title: "姓名",
					key: "name",
					minWidth: 160
				},
				{
					title: "性别",
					key: "sex",
					minWidth: 80
				},
				{
					title: "身份证号",
					key: "idCardNo",
					minWidth: 180
				},
				{
					title: "联系电话",
					key: "phone",
					minWidth: 160
				},
				{
					title: "黑名单",
					key: "isBlack",
					minWidth: 160,
					render(h, { row }) {
						return h("span", {}, row.isBlack ? "是" : "否")
					}
				},
				{
					title: "操作人",
					key: "createdBy",
					minWidth: 120
				},
				{
					title: "创建时间",
					key: "createdTime",
					minWidth: 180
				},
				{
					title: "操作",
					key: "action",
					width: 200,
					fixed: "right",
					renderButton: () => {
						const actionList = [
							{
								label: "查看详情",
								on: {
									click: this.viewHandle
								}
							},
							{
								label: "修改",
								on: {
									click: this.updateHandle
								}
							},
							{
								label: "删除",
								on: {
									click: this.deleteHandle
								},
								confirm: {
									title: "您确认删除这条数据吗？"
								}
							}
						]
						if (
							this.systemActionPermissions.indexOf(
								"btn_subject_patients_add"
							) === -1
						) {
							actionList[1] = null
							actionList[2] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			viewVisible: false,
			listData: [],
			addVisible: false,
			updateId: "",
			viewId: "",
			sexList: [],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			searchParams: {
				name: "",
				idCardNo: "",
				isBlack: ""
			},
			oldSearchParams: {},
			exportLoading: false
		}
	},
	methods: {
		// 删除受试者
		deleteHandle({ row }) {
			this.$asyncDo(async () => {
				const res = await apiDelete({
					id: row.id
				})
				if (res) {
					this.$Message.success("删除成功")
					this.initList()
				}
			})
		},

		viewHandle({ row }) {
			this.viewVisible = true
			this.viewId = row.id
		},
		handleViewOk() {
			this.viewVisible = false
			this.initList()
		},
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},
		// 新增受试者
		addHandle() {
			this.addVisible = true
			this.updateId = ""
		},
		// 导出列表
		exportHandle() {
			this.$asyncDo(async () => {
				this.exportLoading = true
				window.open(
					`${process.env.VUE_APP_REQUEST_BASE_URL}/patient-local/export-patients?${paramsStringify(
						{
							token: this.$store.state.user.token,
							...this.oldSearchParams
						}
					)}`
				)
				this.exportLoading = false
			})
		},
		// 修改受试者
		updateHandle({ row }) {
			this.addVisible = true
			this.updateId = row.id
		},
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getSubjectList({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		getSexList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await publicGetDictionaryList()
				if (res) {
					this.sexList = res.data.find(el => el.id === "6").leaf
				}
				this.loading = false
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				name: "",
				idCardNo: "",
				isBlack: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		}),
		...mapState("permission", ["systemActionPermissions"])
	},
	components: {
		Add,
		ViewData
	},
	created() {
		this.getSexList()
		this.initList()
	}
}
</script>
