<template>
	<Modal
		:title="title"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<!--用户名-->
				<Col span="24">
					<FormItem label="受试者姓名" prop="name">
						<Input v-model="formData.name" placeholder="请输入受试者姓名" />
					</FormItem>
				</Col>
				<!--性别-->
				<Col span="24">
					<FormItem label="性别" prop="sex">
						<Select v-model="formData.sex" placeholder="请选择性别">
							<Option v-for="item in sexList" :key="item.id" :value="item.name">
								{{ item.name }}
							</Option>
						</Select>
					</FormItem>
				</Col>
				<!--手机号码-->
				<Col span="24">
					<FormItem label="联系电话" prop="phone">
						<Input v-model="formData.phone" placeholder="请输入联系电话" />
					</FormItem>
				</Col>
				<!--身份证号码-->
				<Col span="24">
					<FormItem label="身份证号码" prop="idCardNo">
						<Input
							v-model="formData.idCardNo"
							placeholder="请输入身份证号码"
							:disabled="disabled"
						/>
					</FormItem>
				</Col>
				<!--黑名单-->
				<Col span="24">
					<FormItem label="黑名单" prop="isBlack">
						<RadioGroup v-model="formData.isBlack">
							<Radio :label="1">是</Radio>
							<Radio :label="0">否</Radio>
						</RadioGroup>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/subject/subjectlist"

const { apiGet, apiAdd, apiUpdate } = api

export default {
	name: "Add",
	props: {
		visible: {
			default: false
		},
		id: {
			default: ""
		},
		sexList: {
			default: []
		}
	},
	data() {
		return {
			loading: false,
			formData: {
				isBlack: 0
			},
			title: "",
			departmentList: [],
			// 是否是医院，如果组织不是医院，则不需要选择部门
			rules: {
				name: {
					required: true,
					message: "受试者姓名不能为空",
					trigger: "blur"
				},

				sex: {
					required: true,
					message: "性别不能为空",
					trigger: "blur"
				},
				phone: [
					{
						required: true,
						message: "联系电话不能为空",
						trigger: "blur"
					}
				],
				idCardNo: [
					{
						required: true,
						message: "身份证号码不能为空",
						trigger: "blur"
					},
					{
						...this.rules.cardId,
						message: "身份证号码格式不正确",
						trigger: "blur"
					}
				]
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {
					isBlack: 0
				}
				this.title = "新增受试者"
				if (this.id) {
					this.title = "修改受试者"
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({
							id: this.id
						})
						if (res) {
							this.formData = {
								isBlack: 0,
								...this.formData,
								...res.data
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.id) {
							res = await apiUpdate({
								...this.formData
							})
						} else {
							res = await apiAdd({
								...this.formData
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
			return true
		}
	},
	computed: {
		disabled() {
			if (this.id) {
				return true
			}
			return false
		}
	}
}
</script>

<style scoped></style>
